/* App.css */

/* General Styling for the Badge */
.circle-badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 12px;
  transform: translate(50%, -50%);
  /* Centers the badge */
}

/* Mobile Devices (up to 576px) */
@media (max-width: 576px) {
  .circle-badge {
    top: 20px;
    right: 15px;
  }

  .sticky-btn {
    right: 15px;
  }
  .sticky-category-btn .btn {
    right: 15px;
  }
}

/* Tablets (576px to 768px) */
@media (min-width: 576px) and (max-width: 768px) {
  .circle-badge {
    top: -10px;
    right: -10px;
  }

  .sticky-btn {
    right: -10px;
  }

  .sticky-category-btn .btn {
    right: -10px;
  }
}

/* iPad Portrait (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .circle-badge {
    top: 25px;
    right: 60px;
  }

  .sticky-btn {
    right: 60px;
  }

  .sticky-category-btn .btn {
    right: 60px;
  }
}

/* iPad Landscape (1024px to 1366px) */
@media (min-width: 1024px) and (max-width: 1366px) {
  .circle-badge {
    top: 25px;
    right: 45px;
  }

  .sticky-btn {
    right: 45px;
  }

  .sticky-category-btn .btn {
    right: 45px;
  }
}

/* Larger Devices (1366px and above) */
@media (min-width: 1366px) {
  .circle-badge {
    top: 25px;
    right: 315px;
  }

  .sticky-btn {
    right: 315px;
  }

  .sticky-category-btn .btn {
    right: 315px;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) and (min-height: 720px) {
  .circle-badge {
    top: 25px;
    right: 80px;
  }

  .sticky-btn {
    right: 80px;
  }

  .sticky-category-btn .btn {
    right: 80px;
  }
}

/* Laptop with HiDPI 1440x900 resolution */
@media (min-width: 1440px) and (max-width: 1600px) and (min-height: 900px) and (max-height: 1050px) {
  .circle-badge {
    top: 25px;
    right: 70px;
  }

  .sticky-btn {
    right: 70px;
  }

  .sticky-category-btn .btn {
    right: 70px;
  }
}

/* Optional: Styling for the button that holds the icon */
.icon-button {
  padding: 10px;
  font-size: 24px;
  position: relative;
  border: none;
  background-color: transparent;
}

/* Sticky button styles */
.sticky-btn {
  position: fixed;
  bottom: 20px;
  background-color: #218739;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sticky-btn:hover {
  background-color: #218838;
}

.price-input-group {
  width: 100px;
  /* Keep the input textbox small */
}

.price-input {
  font-size: 0.9rem;
  /* Slightly smaller text in the input */
  padding: 5px;
  /* Padding for a smaller input field */
}

.calculated-price {
  font-weight: bold;
  /* Display price in bold */
  font-size: 1rem;
  /* Slightly larger font for emphasis */
}

.total-price {
  font-weight: bold;
  font-size: 1.2rem;
  margin-left: auto;
}

/* Sticky dropdown styles */
.sticky-dropdown {
  position: fixed;
  bottom: 80px; /* Just above the "See Your List" button */
  right: 20px;
  z-index: 1000;
  background-color: white;
}

.sticky-dropdown .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

/* Sticky button styles */
.sticky-category-btn .btn{
  position: fixed;
  bottom: 70px;
  background-color: #218739;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sticky-category-btn .btn :hover {
  background-color: #218838;
}

/* Sticky dropdown styles */
.sticky-dropdown {
  position: fixed;
  bottom: 130px; /* Positioned just above the "Categories" button */
  right: 20px;
  z-index: 1000;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sticky-dropdown .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}